/* eslint-disable no-unused-vars */
import Flickity from "flickity";

const homeEventsCarousel = () => {
    const carousel = document.querySelector(".carousel--home-events");

    if (carousel) {
        const flkty = new Flickity(carousel, {
            wrapAround: true,
            groupCells: true,
            cellAlign: "center",
            bgLazyLoad: true,
            prevNextButtons: false,
        });

        const prev = document.querySelector(
            ".carousel-controls--home-events .previous"
        );
        const next = document.querySelector(
            ".carousel-controls--home-events .next"
        );

        const nextSlide = () => flkty.next();
        const prevSlide = () => flkty.previous();

        next.addEventListener("click", nextSlide, false);
        prev.addEventListener("click", prevSlide, false);
    }
};

const homeFeaturedCarousel = () => {
    const carousel = document.querySelector(".carousel--home-featured");
    const newsList = document.querySelector(".news__list");
    if (newsList) {
        carousel.style.height = `${newsList.clientHeight}px`;
    }

    if (carousel) {
        const flkty = new Flickity(carousel, {
            selectedAttraction: 0.2,
            friction: 0.8,
            wrapAround: true,
            bgLazyLoad: 6,
            pageDots: false,
            prevNextButtons: false,
            setGallerySize: false,
        });

        const prev = document.querySelector(
            ".carousel-controls--home-featured .previous"
        );
        const next = document.querySelector(
            ".carousel-controls--home-featured .next"
        );

        const nextSlide = () => flkty.next();
        const prevSlide = () => flkty.previous();

        next.addEventListener("click", nextSlide, false);
        prev.addEventListener("click", prevSlide, false);
    }
};

const carouselGeneric = () => {
    const carousel = document.querySelector(".carousel--generic");

    if (carousel) {
        const flkty = new Flickity(carousel, {
            selectedAttraction: 0.2,
            friction: 0.8,
            wrapAround: true,
            adaptiveHeight: true,
            bgLazyLoad: 2,
            prevNextButtons: false,
            draggable: ">1",
        });

        const prev = document.querySelector(".carousel-controls .previous");
        const next = document.querySelector(".carousel-controls .next");

        const nextSlide = () => flkty.next();
        const prevSlide = () => flkty.previous();

        next.addEventListener("click", nextSlide, false);
        prev.addEventListener("click", prevSlide, false);
    }
};

export { homeEventsCarousel, homeFeaturedCarousel, carouselGeneric };
