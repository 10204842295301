// Adds icons and listeners menu items with children.
const navSetup = () => {
  const hasChildren = document.querySelectorAll(".view-more");

  const toggleChildren = (e) => {
    e.target.closest(".has-children").classList.toggle("open");
  };

  hasChildren.forEach((child) =>
    child.addEventListener("click", toggleChildren, false)
  );
};

export { navSetup };
