//mobile dropdown menu

const navToggler = () => {
  const hamburger = document.getElementById("btn--nav");

  function toggleNav() {
    const menu = document.getElementById("header__nav");
    const menuBars = document.querySelector(".menu-bars");

    menuBars.classList.toggle("open");
    menu.classList.toggle("open");
  }

  hamburger.addEventListener("click", toggleNav, false);
};

export { navToggler };
