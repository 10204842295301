// Main UI Components
import { loaders } from "./modules/figLoader";
import { navToggler } from "./modules/navToggler";
import { navSetup } from "./modules/navSetup";
import "@css/styles.css";

// Carousels
import {
  homeEventsCarousel,
  homeFeaturedCarousel,
  carouselGeneric,
} from "./modules/carousels";

const coreInit = () => {
  navSetup();
  navToggler();
  loaders();
};


document.addEventListener("DOMContentLoaded", coreInit);

/**
 *  These are loaded with window.onload because they check the height of fully
 * rendered divs. DOMContentLoaded will run them before the images are loaded.
 * 
 * */ 
window.onload = function() {
    homeEventsCarousel();
    homeFeaturedCarousel();
    carouselGeneric();
}
