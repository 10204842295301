const loaders = () => {
  let figLoaders = document.querySelectorAll("figure");

  figLoaders.forEach((figLoader) => {
    let figImg = figLoader.querySelector("img");
    // src/css/base/_loading.scss
    figLoader.classList.add("loading");
    if (figImg && !figImg.complete) {
      figLoader.classList.add("animate-pulse");
      figImg.addEventListener("load", () => {
        figLoader.classList.remove("animate-pulse");
      });

      figImg.addEventListener("error", () => {
        figLoader.classList.remove("animate-pulse");
        figImg.style.visibility = "hidden";
      });
    }
  });
};

export { loaders };
